// Copyright © 2024 The Things Industries B.V.

import React from 'react'
import { defineMessages, FormattedNumber, FormattedTime } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'

import { APPLICATION } from '@console/constants/entities'

import { IconDevice, IconPlus } from '@ttn-lw/components/icon'
import Panel from '@ttn-lw/components/panel'
import Button from '@ttn-lw/components/button'
import Status from '@ttn-lw/components/status'

import Message from '@ttn-lw/lib/components/message'

import TotalEndDevicesUpsellerPanel from '@console/components/total-end-devices-upseller-panel'

import PropTypes from '@ttn-lw/lib/prop-types'
import {
  selectNocEnabled,
  selectNocUrl,
  selectPluginTTSSandboxEnabled,
} from '@ttn-lw/lib/selectors/env'
import sharedMessages from '@ttn-lw/lib/shared-messages'

import { setSearchOpen, setSearchScope } from '@console/store/actions/search'

import { selectUserIsAdmin } from '@console/store/selectors/user'

import style from './total-end-devices-panel.tti.styl'

const m = defineMessages({
  addEndDevice: 'Add end device',
  noEndDevicesTitle: 'No end devices',
  noEndDevicesSubtitle: 'Your end devices will appear here.',
  unlimited: 'Unlimited',
  updateToday: 'Update: Today at {time}',
})

const TotalEndDevicesPanel = ({ totalCount }) => {
  const nocUrl = `${selectNocUrl()}/d/extended-ttsapplication/application-details`
  const nocEnabled = selectNocEnabled()
  const sandboxEnabled = useSelector(selectPluginTTSSandboxEnabled)
  const isAdmin = useSelector(selectUserIsAdmin)
  const dispatch = useDispatch()

  const handleRegisterDeviceClick = React.useCallback(() => {
    dispatch(setSearchScope(APPLICATION))
    dispatch(setSearchOpen(true))
  }, [dispatch])

  // The rendering of the panels is based on the user's permissions and the availability of the NOC.
  // Note the different setups:
  // 1. isAdmin
  //   a. nocEnabled - show panel
  //   b. !nocEnabled - blurry panel
  // 2. !isAdmin && sandbox - blurry panel
  // 3. all other cases - nothing

  const isNocEnabled = nocEnabled && Boolean(nocUrl)

  if (!isAdmin && sandboxEnabled) {
    return <TotalEndDevicesUpsellerPanel />
  }

  if (isAdmin) {
    if (!isNocEnabled) {
      return <TotalEndDevicesUpsellerPanel />
    }
    return (
      <Panel
        title={sharedMessages.totalEndDevices}
        icon={IconDevice}
        shortCutLinkTitle={sharedMessages.nocShortened}
        shortCutLinkPath={nocUrl}
        shortCutLinkTarget={'_blank'}
        className={style.panel}
        compact
      >
        {totalCount !== 0 ? (
          <div className={classnames(style.countDiv, 'd-flex al-center j-center gap-cs-xs h-full')}>
            <Status status="green" pulse className="d-flex al-center">
              <span className="c-text-neutral-heavy fw-bold fs-xl4">
                <FormattedNumber value={totalCount} />
              </span>
            </Status>
            <Message
              className="c-text-neutral-light"
              content={m.updateToday}
              values={{
                time: (
                  <FormattedTime
                    value={new Date()}
                    hour="2-digit"
                    minute="2-digit"
                    hour12={false}
                  />
                ),
              }}
            />
          </div>
        ) : (
          <div className={style.content}>
            <Message
              className="c-text-neutral-heavy fw-bold fs-l text-center"
              content={m.noEndDevicesTitle}
            />
            <Message
              className="c-text-neutral-light fs-m text-center mb-cs-l"
              content={m.noEndDevicesSubtitle}
            />

            <Button
              primary
              message={m.addEndDevice}
              icon={IconPlus}
              onClick={handleRegisterDeviceClick}
            />
          </div>
        )}
      </Panel>
    )
  }

  return null
}

TotalEndDevicesPanel.propTypes = {
  totalCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
}

export default TotalEndDevicesPanel
