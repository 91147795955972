// Copyright © 2024 The Things Industries B.V.

import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { APPLICATION, GATEWAY } from '@console/constants/entities'

import { IconApplication, IconGateway } from '@ttn-lw/components/icon'

import RequireRequest from '@ttn-lw/lib/components/require-request'

import NocMetricsPanel from '@console/containers/noc-metrics-panel/index.tti'
import TotalEndDevicesPanel from '@console/containers/total-end-devices-panel/index.tti'

import { subtractDays } from '@ttn-lw/lib/date-utils'
import { selectNocEnabled, selectNocUrl } from '@ttn-lw/lib/selectors/env'
import sharedMessages from '@ttn-lw/lib/shared-messages'

import {
  getNocActiveApplications,
  getNocConnectedGateways,
} from '@console/store/actions/network-operations-center.tti'
import { getRegistryTotals } from '@console/store/actions/tenant.tti'

import {
  selectNocActiveApplicationsData,
  selectNocConnectedGatewaysData,
} from '@console/store/selectors/network-operations-center.tti'
import { selectRegistryTotals } from '@console/store/selectors/tenant.tti'
import { selectUserIsAdmin } from '@console/store/selectors/user'

const NocPanels = ({ showRandomValues }) => {
  const registryTotals = useSelector(selectRegistryTotals)
  const nocUrl = selectNocUrl()
  const nocEnabled = selectNocEnabled()
  const isAdmin = useSelector(selectUserIsAdmin)
  const totalGateways = !showRandomValues ? registryTotals?.gateways ?? 0 : 10
  const totalApplications = !showRandomValues ? registryTotals?.applications ?? 0 : 100
  const totalEndDevices = !showRandomValues ? registryTotals?.end_devices ?? 0 : 1000

  const connectedGatewaysData = useSelector(selectNocConnectedGatewaysData)
  const activeApplicationsData = useSelector(selectNocActiveApplicationsData)

  const from = subtractDays(new Date(), 30).toISOString()
  const to = new Date().toISOString()
  const bucketInterval = '300s'

  const requestActions = []

  const isNocEnabled = nocEnabled && Boolean(nocUrl)

  if (!showRandomValues && isAdmin && isNocEnabled) {
    requestActions.push(getRegistryTotals())
  }

  return (
    <RequireRequest requestAction={requestActions} handleErrors={false}>
      <div className="item-12 md-lg:item-4">
        <NocMetricsPanel
          title={sharedMessages.activeApplications}
          icon={IconApplication}
          fetchData={() => getNocActiveApplications(from, to, bucketInterval)}
          data={activeApplicationsData}
          totalCount={totalApplications}
          showRandomValues={showRandomValues}
          entity={APPLICATION}
          entityPath={'/applications'}
        />
      </div>
      <div className="item-12 md-lg:item-4">
        <NocMetricsPanel
          title={sharedMessages.connectedGateways}
          icon={IconGateway}
          fetchData={() => getNocConnectedGateways(from, to, bucketInterval)}
          data={connectedGatewaysData}
          totalCount={totalGateways}
          showRandomValues={showRandomValues}
          entity={GATEWAY}
          entityPath={'/gateways'}
        />
      </div>
      <div className="item-12 md-lg:item-4">
        <TotalEndDevicesPanel totalCount={totalEndDevices} />
      </div>
    </RequireRequest>
  )
}

NocPanels.propTypes = {
  showRandomValues: PropTypes.bool,
}

NocPanels.defaultProps = {
  showRandomValues: false,
}

export default NocPanels
